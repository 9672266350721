<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ data.title || $t('Informação do pedido') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>

        <b-container
          class="p-2"
          fluid
        >
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('Nome')"
              >
                <p
                  class="text-primary mb-0"
                >
                  {{ data.sw254s02 + ((data.sw254s03 !== null) ? ' ' + data.sw254s03 : '') }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('Email')"
              >
                <p
                  class="text-primary mb-0"
                >
                  {{ data.sw254s04 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('Telefone')"
              >
                <p
                  class="text-primary mb-0"
                >
                  {{ data.sw254s05 || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('Data do pedido')"
              >
                <p
                  class="text-primary mb-0"
                >
                  {{ truncateTxt(data.sw254s11, 16) || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('Data de validação do pedido')"
              >
                <p
                  class="text-primary mb-0"
                >
                  {{ truncateTxt(data.sw254s12, 16) || '-----' }}
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row
            v-if="$store.getters['auth/currentUser'].id_role===1"
          >
            <b-col md="12">
              <b-form-group
                :label="$t('Feito a')"
              >
                <p
                  v-if="data.sw254s06==='rede'"
                  class="text-primary mb-0"
                >
                  {{ $t('Toda a rede') }}
                </p>
                <p
                  v-if="data.sw254s06==='HUB'"
                  class="text-primary mb-0"
                >
                  {{ data.HUB }}
                </p>
                <p
                  v-if="data.sw254s06==='consultor'"
                  class="text-primary mb-0"
                >
                  {{ data.consultor }} <small>{{ data.HUB }}</small>
                </p>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('Observações')"
              >
                <!-- eslint-disable vue/no-v-html -->
                <p
                  class="text-primary mb-0"
                  v-html="data.sw254s10 || '-----'"
                />
                <!--eslint-enable-->
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>

      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BRow, BCol, BContainer, BFormGroup,
} from 'bootstrap-vue'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { mixFormGeral } from '../../mixins'

export default {
  components: {
    BSidebar,
    BRow,
    BCol,
    BContainer,
    BFormGroup,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
    data: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: false,
      default: () => {},
    },
  },
  methods: {
    closeModal() {
      if (typeof this.close === 'function') {
        this.close()
      }
    },
  },
}
</script>
